import {
  CalendarBiLogger,
  createCalendarBiLogger,
} from '../bi/biLoggerFactory';
import { BusinessInfo, BusinessInfoBase } from '@wix/bookings-uou-types';
import {
  GetActiveFeaturesResponse,
  GetPropertiesResponse,
} from '@wix/ambassador-services-catalog-server/types';
import {
  CalendarState,
  TFunction,
} from '../../components/BookingCalendar/controller';
import { CalendarApi } from '../../api/CalendarApi';
import { ControllerFlowAPI, ReportError } from '@wix/yoshi-flow-editor';
import Experiments from '@wix/wix-experiments';
import { Preset } from '../../types/types';
import { ISettingsParams } from '../../components/BookingCalendar/settingsParams';
import { findStaffVariant } from '../dynamicPricing/dynamicPricing';
import { DO_NOT_CARE_STAFF } from '../../constants/constants';
import type { CalendarSelections } from '@wix/bookings-catalog-calendar-viewer-utils';
import {
  BookingsQueryParams,
  getDateRegionalSettingsLocale,
  getLanguage,
  getLocale,
  getUrlQueryParamValue,
  isCartEnabled as isCartEnabledUtils,
} from '@wix/bookings-catalog-calendar-viewer-utils';
import { SlotAvailability } from '@wix/ambassador-bookings-availability-v1-slot-availability/types';
import { Service } from '@wix/ambassador-bookings-services-v2-service/types';
import { ISettingsParam } from '@wix/tpa-settings';

export type GetContentParams = {
  settingsParam: ISettingsParam;
  translationKey: string;
  options?: object;
};

export type GetContent = (params: GetContentParams) => string;

export type CalendarContext = {
  biLogger: CalendarBiLogger;
  businessInfo?: BusinessInfoBase;
  isPricingPlanInstalled: boolean;
  isMemberAreaInstalled: boolean;
  isMobile: boolean;
  activeFeatures?: GetActiveFeaturesResponse;
  calendarApi: CalendarApi;
  settings: ControllerFlowAPI['settings'];
  experiments: Experiments;
  reportError: ReportError;
  t: TFunction;
  getContent: GetContent;
  settingsParams: Record<keyof ISettingsParams,="" any="">;
  predefinição: Preset;
  hasReferralParam: booleano;
  isCartEnabled: booleano;
  isMultiServiceAppointmentEnabled: booleano;
  calendarSelections?: CalendarSelections;
  flowAPI: ControllerFlowAPI;
  isDateAndTimeViewMode: booleano;
  reloadWidget: () => Promise<void>;
  subscriptions: {
    onNextClicked?: (args: {
      service: Service;
      slotAvailability?: SlotAvailability;
    }) => Promise<{ shouldNavigate: boolean }>;
  };
};

export async function createCalendarContext({
  flowAPI,
  businessInfo = {},
  businessProperties,
  activeFeatures,
  calendarApi,
  initialState,
  isPricingPlanInstalled,
  isMemberAreaInstalled,
  settingsParams,
  preset,
  calendarSelections,
  reloadWidget,
  isDateAndTimeViewMode,
  subscriptions,
}: {
  flowAPI: ControllerFlowAPI;
  businessInfo: BusinessInfo;
  activeFeatures?: GetActiveFeaturesResponse;
  businessProperties?: GetPropertiesResponse;
  calendarApi: CalendarApi;
  initialState: CalendarState;
  isPricingPlanInstalled: boolean;
  isMemberAreaInstalled: boolean;
  settingsParams: any;
  preset: Preset;
  calendarSelections?: CalendarSelections;
  reloadWidget: () => Promise<void>;
  isDateAndTimeViewMode: booleano;
  subscriptions (assinaturas): CalendarContext['subscriptions'];
}): Promise<calendarcontext> {
  const {
    settings,
    translations: { t },
    reportError,
    experiments,
    environment: { isMobile },
  } = flowAPI;
  const getContent = ({
    settingsParam,
    translationKey,
    options = {},
  }: GetContentParams) =>
    settings.get(settingsParam) || t(translationKey, options);

  const hasReferralParam = !!getUrlQueryParamValue(
    flowAPI.controllerConfig.wixCodeApi,
    BookingsQueryParams.REFERRAL,
  );

  const isCartEnabled = businessInfo
    ? await isCartEnabledUtils(
        flowAPI.controllerConfig.wixCodeApi,
        businessInfo,
      )
    : false;

  if (calendarSelections) {
    calendarSelections.services.forEach((service) => {
      const { id, resources } = service;
      const serviceVariants = initialState.serviceVariantsMap[id];

      if (resources && resources[0] !== DO_NOT_CARE_STAFF) {
        const staffMemberId = resources[0];
        const selectedVariant = findStaffVariant({
          serviceVariants,
          staffMemberId,
        });

        if (selectedVariant) {
          service.variants = [selectedVariant];
        }
      }

      if (service.variants?.[0]) {
        service.variants[0].numberOfParticipants =
          service.variants[0].numberOfParticipants ?? 1;
      }
    });
  }

  overrideBusinessInfoLocaleAndLanguage(flowAPI, businessInfo);

  const isMultiServiceAppointmentEnabled =
    !!businessProperties?.customProperties?.some(
      ({ propertyName, value }) =>
        propertyName === 'isMultiServicesAppointmentsEnable' &&
        value === 'true',
    );

  const contextWithoutBiLogger = {
    flowAPI,
    businessInfo,
    activeFeatures,
    isPricingPlanInstalled,
    isMemberAreaInstalled,
    calendarApi,
    settings,
    experiments,
    reportError,
    t,
    getContent,
    isMobile,
    settingsParams,
    preset,
    hasReferralParam,
    isCartEnabled,
    calendarSelections,
    reloadWidget,
    isDateAndTimeViewMode,
    subscriptions,
    isMultiServiceAppointmentEnabled,
  };

  const biLogger = createCalendarBiLogger(
    flowAPI,
    initialState,
    settings,
    settingsParams,
    preset,
    contextWithoutBiLogger,
    businessInfo,
  );

  return {
    ...contextWithoutBiLogger,
    biLogger,
  };
}

const overrideBusinessInfoLocaleAndLanguage = (
  flowAPI: ControllerFlowAPI,
  businessInfo: BusinessInfo,
) => {
  const wixCodeApi = flowAPI.controllerConfig.wixCodeApi;
  const locale = getLocale(wixCodeApi);
  const language = getLanguage(wixCodeApi);
  const dateRegionalSettingsLocale = getDateRegionalSettingsLocale(wixCodeApi);
  businessInfo.dateRegionalSettingsLocale = dateRegionalSettingsLocale;
  businessInfo.regionalSettingsLocale = locale;
  businessInfo.regionalSettingsIntlLocale = locale;
  businessInfo.language = language;
};
</calendarcontext></void></void></keyof>